/**
 * Deeply compares the provided arguments.
 *
 * Adapted from angus-c/just.
 *
 * @link https://github.com/angus-c/just/tree/master/packages/collection-compare
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
export default function isEqual(a, b) {
  if (a === b) {
    return true;
  }
  if (a !== a && b !== b) {
    return true;
  }
  if (typeof a !== typeof b || {}.toString.call(a) != {}.toString.call(b)) {
    return false;
  }
  if (a !== Object(a)) {
    return false;
  }
  if (!a) {
    return false;
  }
  if (Array.isArray(a)) {
    return isEqualArray(a, b);
  }
  if (isSet(a)) {
    return isEqualArray(Array.from(a), Array.from(b));
  }
  if (isObject(a)) {
    return isEqualObject(a, b);
  }
  return a.toString() === b.toString();
}

function isEqualArray(a, b) {
  const len = a.length;
  if (len !== b.length) {
    return false;
  }
  for (let i = 0; i < len; i++) {
    if (!isEqual(a[i], b[i])) {
      return false;
    }
  }
  return true;
}

function isEqualObject(a, b) {
  const keys1 = Object.keys(a);
  const len = keys1.length;
  if (len !== Object.keys(b).length) {
    return false;
  }
  for (let i = 0; i < len; i++) {
    const key1 = keys1[i];
    if (
      !Object.prototype.hasOwnProperty.call(b, key1) ||
      !isEqual(a[key1], b[key1])
    ) {
      return false;
    }
  }
  return true;
}

function isObject(o) {
  return Object.prototype.toString.call(o) === '[object Object]';
}

function isSet(o) {
  return Object.prototype.toString.call(o) === '[object Set]';
}
