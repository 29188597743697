/**
 * Tracks arbitrary event data with Segment
 *
 * @param {string} uri The invitee URI returned by Calendly on submission
 * @returns {Invitee|Error}
 */
export default async function getCalendlyInvitee(uri) {
  const response = await fetch(uri, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_CALENDLY_ACCESS_TOKEN}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to fetch invitee details from Calendly: ${response.statusText}`
    );
  }

  const data = await response.json();
  const inviteeEmail = data?.resource?.email;

  if (!inviteeEmail) {
    throw new Error('Invitee email not found in response data.');
  }

  return {
    firstName: data?.resource?.first_name,
    lastName: data?.resource?.last_name,
    email: inviteeEmail,
  };
}
