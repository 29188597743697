const breakpoints = {
  md: '40em',
  lg: '64em',
  nav: '74em',
};

// The color palette is located here:
// https://www.figma.com/file/7jbD1pyLbPUJlKcZOx8P5y/Sleuth-Marketing-Site?node-id=274-176&t=VMP0ApEfO1aC786C-4

const colors = {
  // blues
  bgBlueGray: '#F7FBFC',
  lightestBlue: '#EBF5FA',
  skyBlue: '#6EAFD3', // not defined in style guide

  // teals
  lightestTeal: '#AFD6EC',
  lightTeal: '#5195B9',
  teal: '#236A91',
  mediumTeal: '#164159',
  darkTeal: '#0F2F40',
  darkestTeal: '#051620',

  textTeal: '#43667A',
  textGray: '#C1D1D9',
  white: '#fff',

  // accent
  yellow: '#F2C80B',
  red: '#CB2138',
  orange: '#ED6F39',

  // grays
  gray: {
    50: '#e9eff2',
  },
};

colors.text = {
  base: colors.darkTeal,
  muted: colors.textGray,
};

const sizes = {
  md: '40em',
  lg: '64em',
};

const fonts = { system: "'system-ui', sans-serif" };
fonts.satoshi = `'Satoshi', ${fonts.system}`;
fonts.inter = `'Inter', ${fonts.system}`;
fonts.base = fonts.inter;
fonts.heading = fonts.satoshi;
fonts.button = fonts.satoshi;

const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
};

const zIndex = {
  banner: 100,
  popover: 200,
  dialog: 1000,
};

const theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  sizes,
  zIndex,
};

export default theme;
