import * as React from 'react';

import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';
import { ThemeProvider } from 'styled-components';

import theme from 'styles/theme';

/**
 * process.env.CONTEXT is a build variable set by Netlify.
 * @link https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
 */
const getDeployContext = () => {
  switch (process.env.CONTEXT) {
    case 'branch-deploy':
    case 'deploy-preview': {
      return 'preview';
    }
    case 'production': {
      return 'production';
    }
    default: {
      return 'development';
    }
  }
};

const rollbarConfig = {
  accessToken: process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
  environment: getDeployContext(),
  checkIgnore: () => getDeployContext() !== 'production',
};

/**
 * @link https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
 * @link https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr/#wrapRootElement
 */
export default function wrapRootElement({ element }) {
  return (
    <RollbarProvider config={rollbarConfig}>
      <RollbarErrorBoundary>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
}
