import { fonts } from 'styles/fonts';
import posthog from 'posthog-js';

import { isDev } from 'utils';

export { default as wrapRootElement } from './wrap-root-element';

posthog.init(process.env.GATSBY_POSTHOG_API_KEY, {
  loaded: (posthog) => {
    window.posthog = posthog;

    if (isDev()) {
      posthog.opt_out_capturing();
    }
  },
});

export function onInitialClientRender() {
  // Load fonts.
  if (typeof document !== 'undefined' && 'fonts' in document) {
    fonts.forEach(async ({ family, src, descriptors }) => {
      const source = `url(${src.url}) format('${src.format}')`;
      const fontFace = new FontFace(family, source, descriptors);
      const font = await fontFace.load();
      document.fonts.add(font);
    });
  }

  // A 'scrollend' event polyfill.
  // Used by the Carousel component.
  // @link https://github.com/argyleink/scrollyfills
  // eslint-disable-next-line no-unused-vars
  import('scrollyfills');
}
