import { isDev, isPreview } from 'utils';

/**
 * Tracks arbitrary event data with Segment
 *
 * @param {string} event The event name
 * @param {Object} payload The event data or properties
 * @param {Object} [options] Tracking options
 * @param {boolean} [options.once] If true, the event won't be tracked more
 *   than once. Note that simple equality is used as the basis for comparison.
 * @returns {boolean|Error}
 */
export function trackEvent(event, payload, options = {}) {
  let result;

  try {
    let isTracked = false;
    if (options.once) {
      isTracked = window.analytics.some(
        ([method, _event, _payload]) =>
          method === 'track' && _event === event && _payload === payload
      );
    }
    result = isTracked || !!window.analytics.track(event, payload);
  } catch (error) {
    result = error;
  }

  if (isDev() || isPreview()) {
    // Log event info for debugging
    console.group('trackEvent()');
    if (result instanceof Error) {
      console.error("Couldn't track event.", result);
    }
    console.dir({ name: event, payload });
    console.groupEnd();
  }

  return result;
}

export const PAGE_REGIONS = {
  footer: 'footer',
  hero: 'hero',
  mid_body: 'mid_body',
  pricing_card: 'pricing_card',
  right_rail: 'right_rail',
  topnav: 'topnav',
};

/**
 * Returns the page region for a given element.
 *
 * The function searches for the nearest ancestor of the provided element for
 * which a region can be determined. Page region attributes should be set with
 * getPageRegionProps().
 *
 * Together, this function and getPageRegionProps() aim to provide a minimal
 * API for consistently associating tracked events with regions of the page.
 *
 * @param {*} el The element to examine.
 * @returns {string|undefined} The page region for the provided element.
 */
export function getClosestPageRegion(el) {
  if (el instanceof Element && el.closest('[data-page-region]')) {
    return el.closest('[data-page-region]')?.dataset.pageRegion;
  }
  return undefined;
}

/**
 * Returns props for associating a component with a given region.
 *
 * Page regions are used by our tracking
 *
 * @param {string} region The region name to reference.
 * @throws The function will throw if the provided region is not among those
 *         enumerated in PAGE_REGIONS.
 * @returns {object} Component props ready for spreading.
 */
export function getPageRegionProps(region) {
  if (region in PAGE_REGIONS) {
    return { 'data-page-region': PAGE_REGIONS[region] };
  }
  throw new Error(
    `getPageRegionProps() was called with the unsupported region "${region}"`
  );
}
